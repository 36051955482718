import { GraphQLQuery, GraphQLResult } from "@aws-amplify/api";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/auth";
import { API } from "aws-amplify";

/**
 * This function makes API GraphQL calls and returns the error message if there is one.
 * By default, amplify returns 200 even if there is an error.
 * @param query GraphQL query
 * @param variables GraphQL variables
 * @returns GraphQLResult promise
 */
export async function requestWithErrHandler<T, V extends object | undefined>(
  query: string,
  variables?: V,
  authMode?: GRAPHQL_AUTH_MODE
) {
  const params: {
    query: string;
    variables?: V;
    authMode?: GRAPHQL_AUTH_MODE;
  } = { query };

  // not all queries require variables.
  if (variables) params.variables = variables;
  if (authMode) params.authMode = authMode;

  const response = (await API.graphql(params)) as GraphQLResult<
    GraphQLQuery<T>
  >;

  // If errors array exists, throw the first error message.
  if (response?.errors?.length) throw new Error(response.errors[0].message);

  // Rare, but sometimes the data can return null without errors array, e.g incomplete responses.
  if (!response?.data)
    throw new Error("There was an error with your request. Please try again.");

  return response;
}
