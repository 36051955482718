import { Site } from "../types";
import { useSite } from "./useSite";

type FeatureConfigurationKeys = keyof Site["featureConfiguration"];

/** Hook to check if a feature(s) are enabled for the current site. */
export const useCheckFeatureEnabled = (
  features: FeatureConfigurationKeys[]
): Record<FeatureConfigurationKeys, boolean | undefined> => {
  const { data: site } = useSite();

  const featureMap = new Map();

  features.forEach(feature =>
    featureMap.set(feature, site?.featureConfiguration?.[feature])
  );

  return Object.fromEntries(featureMap);
};
