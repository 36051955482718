import { Show, theme } from "@cloudline/cloudline-styled-components";
import { useVisitor } from "features/auth/hooks/useVisitor";
import { useCheckFeatureEnabled } from "features/site/hooks/useCheckFeatureEnabled";
import { useSite } from "features/site/hooks/useSite";
import { useRouter } from "next/router";
import {
  IoCalendar,
  IoHelpCircleSharp,
  IoHome,
  IoTicketSharp
} from "react-icons/io5";
import { RiMapPinFill } from "react-icons/ri";
import styled from "styled-components";
import { getSearchParam } from "utils/getSearchParam";
import { Image } from "./Image";
import { NavbarLink } from "./NavbarLink";

export const NAVBAR_HEIGHT = 80;

const Nav = styled.nav`
  height: ${NAVBAR_HEIGHT}px;
  width: 100%;
  background: #fff;
  position: fixed;
  bottom: 0px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  z-index: 100;
`;

const NavUl = styled.ul`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  list-style: none;
  a {
    text-decoration: none;
    color: ${theme.color.grey[100]};
    :visited {
      color: ${theme.color.grey[100]};
    }
  }
`;

/**
 * Navigation bar on the bottom on the page. Has links to various pages
 * on the app including the Home page, purchases, timeline, FAQ and map
 * page if the current site has one.
 */
export const Navbar = () => {
  const { data: { id: visitorId } = {} } = useVisitor();
  const { data: site } = useSite();
  const { pathname } = useRouter();

  const { mapEnabled, faqEnabled } = useCheckFeatureEnabled([
    "mapEnabled",
    "faqEnabled"
  ]);

  /** Hide the navbar during Checkout, Search, Map and Notice Embed. */
  const hideNavbar =
    ["/checkout", "/search"].includes(pathname) ||
    pathname.startsWith("/map") ||
    !!getSearchParam("noticeId"); // Notice embed

  if (site?.authRequired && !visitorId) return null;

  if (hideNavbar) return null;

  const navItems = [
    {
      link: "/",
      dataPh: "navbarlink home",
      icon: <IoHome size={24} color={theme.color.primary[500]} />
    },
    {
      link: "/purchases",
      dataPh: "navbarlink purchases",
      icon: <IoTicketSharp color={theme.color.primary[500]} size={26} />,
      enabled: !!visitorId
    },
    {
      link: "/whats-on/General%20Schedule",
      dataPh: "navbarlink general schedule",
      icon: <IoCalendar color={theme.color.primary[500]} size={26} />
    },
    {
      link: "/map",
      dataPh: "navbarlink map",
      icon: <RiMapPinFill color={theme.color.primary[500]} size={28} />,
      enabled: mapEnabled
    },
    {
      link: "/help",
      dataPh: "navbarlink help",
      icon: <IoHelpCircleSharp color={theme.color.primary[500]} size={30} />,
      enabled: faqEnabled
    }
  ];

  const embeds = site?.embeds?.items.filter(
    embed => embed.isEnabled && embed._deleted !== true
  );

  /** Adding any Site embeds to the navbar at their selected order index. */
  if (embeds?.length) {
    embeds.forEach(embed => {
      navItems.splice(embed.order, 0, {
        link: `/embed?embed=${embed.id}`,
        dataPh: `navbarlink ${embed.name}`,
        icon: (
          <Image
            alt="embed"
            s3Key={embed.icon?.key || ""}
            width={40}
            height={40}
            fit="contain"
          />
        )
      });
    });
  }

  return (
    <Nav>
      <NavUl>
        {navItems.map(({ link, dataPh, icon, enabled }) => (
          <Show key={link} when={enabled !== false}>
            <NavbarLink link={link} dataPh={dataPh} icon={icon} />
          </Show>
        ))}
      </NavUl>
    </Nav>
  );
};
