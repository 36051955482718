import { create } from "zustand";

interface TagState {
  tags: string[];
  activities: Record<string, string[]>;
}

export const useTags = create<TagState>(() => ({
  tags: [],
  activities: {}
}));
