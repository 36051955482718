import { useSite } from "features/site/hooks/useSite";
import posthog from "posthog-js";
import { useCallback, useEffect } from "react";
import { GeneralConnectionStatus } from "../types";
import { useNetworkStatus } from "./useNetworkStatus";

/** Hook that controls the network state across the app. Runs custom logic when going offline and coming back online, e.g re-initialising subscriptions. */
export const useManageNetwork = () => {
  const { data: site } = useSite();
  const { setConnectionStatus } = useNetworkStatus();

  // Function than runs when the app goes offline.
  const configureOffline = useCallback(() => {
    console.info("App offline");
    posthog.capture("Connection OFFLINE", {
      siteID: site?.id,
      siteName: site?.name,
      organizationID: site?.organization?.id
    });

    if (!navigator.onLine) {
      // Setting the connection status to offline.
      setConnectionStatus(
        GeneralConnectionStatus.Offline,
        "Device offline, please restore connection to this device."
      );
    }
  }, [setConnectionStatus, site?.id, site?.name, site?.organization?.id]);

  // Function that runs when the app comes back online.
  const configureOnline = useCallback(() => {
    posthog.capture("Connection ONLINE", {
      siteID: site?.id,
      siteName: site?.name,
      organizationID: site?.organization?.id
    });
    // Resetting the connection status to Online.
    setConnectionStatus(GeneralConnectionStatus.Online, null);
  }, [setConnectionStatus, site?.id, site?.name, site?.organization?.id]);

  // On page load, add online and offline event listeners.
  useEffect(() => {
    window.addEventListener("online", configureOnline);
    window.addEventListener("offline", configureOffline);

    return () => {
      window.removeEventListener("online", configureOnline);
      window.removeEventListener("offline", configureOffline);
    };
  }, [configureOnline, configureOffline]);
};
