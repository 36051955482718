import {
  Box,
  Center,
  Show,
  theme
} from "@cloudline/cloudline-styled-components";
import Link from "next/link";
import { useRouter } from "next/router";
import { memo } from "react";

interface NavbarLinkProps {
  link: string;
  icon: JSX.Element;
  dataPh: string;
}

export const NavbarLink: React.FC<NavbarLinkProps> = memo(function NavbarLink({
  link,
  icon,
  dataPh
}) {
  const { asPath } = useRouter();
  return (
    <Box
      h="100%"
      w="100%"
      display="flex"
      justify="center"
      align="center"
      position="relative"
      data-ph={dataPh}
    >
      {/** Line for active page */}
      <Show when={asPath === link}>
        <Box
          w="100%"
          h="3px"
          bg={theme.color.primary[500]}
          position="absolute"
          top={0}
        />
      </Show>

      <Link href={link}>
        <Center>{icon}</Center>
      </Link>
    </Box>
  );
});
