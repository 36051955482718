import { useRef } from "react";
import { useEffectOnce } from "./useEffectOnce";

/** Hook called when component is unmounted. */
export const useUnmount = (effect: () => void) => {
  const effectRef = useRef(effect);
  effectRef.current = effect;

  useEffectOnce(() => () => {
    effectRef.current();
  });
};
