import { useQuery } from "@tanstack/react-query";
import { Auth } from "aws-amplify";
import posthog from "posthog-js";
import { requestWithErrHandler } from "utils/requestWithErrHandler";
import { AuthErrorMessage, Visitor, VisitorByOwner } from "../types";

export const fetchVisitor = async () => {
  const user = await Auth.currentUserInfo();

  if (!user) throw new Error(AuthErrorMessage.NoUserFound);

  if (!user.attributes.phone_number_verified)
    throw new Error(AuthErrorMessage.UserNotVerified);

  const response = await requestWithErrHandler<
    VisitorByOwner,
    { owner: string }
  >(fetchVisitorQuery, { owner: user.username });

  /** Whether the user is using in standalone (installed) */
  const isPWA = window.matchMedia("(display-mode: standalone)").matches;

  // Identify the user on Posthog
  posthog.identify(
    user.username,
    {
      email: user.attributes.email,
      isStandalone: isPWA
    },
    {}
  );
  return response?.data?.visitorByOwner?.items[0] as Visitor;
};

/** Capture the current visitor */
export const useVisitor = () =>
  useQuery<Visitor, { message: AuthErrorMessage }>(
    ["visitor"],
    () => fetchVisitor(),
    {
      refetchOnWindowFocus: false,
      retry: false
    }
  );

const fetchVisitorQuery = /* GraphQL */ `
  query VisitorByOwner($owner: String!) {
    visitorByOwner(owner: $owner) {
      items {
        id
        owner
        firstName
        dateOfBirth
        lastName
        phoneNumber
        email
        createdAt
        signUpOrgID
        signUpSiteID
        stripeCustomerID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
