import { SessionBooking } from "../../bookings/types";
import { Plan } from "../../events/types";
import { Notification } from "../../notifications/types";
import { Order } from "../../orders/types";

export interface CognitoUser {
  Session?: string | null;
  authenticationFlowType?: string;
  client?: {
    endpoint?: string;
    userAgent?: string;
  };
  keyPrefix?: string;
  pool?: {
    advancedSecurityDataCollectionFlag?: boolean;
    clientId?: string;
    userPoolId?: string;
  };
  signInUserSession?: {
    accessToken?: {
      jwtToken?: string;
      payload?: {
        [key: string]: {
          "cognito:groups": string[];
        };
      };
    };
  };
  username?: string;
  userConfirmed?: boolean;
  userSub?: string;
  challengeName?: string;
  challengeParam?: { [key: string]: unknown };
  unverified?: {
    email?: string;
    phone_number?: string;
  };
  attributes?: {
    sub?: string;
    phone_number_verified?: boolean;
    phone_number?: string;
    email?: string;
    email_verified?: boolean;
  };
}

export enum AuthRoutePaths {
  // Different routes for logging in.
  Login = "/login",

  // Different routes for signing up.
  // First page to enter email address
  EmailEntry = "/email",

  SignUp = "/signup",

  // After password entry, page shows phone number entry field.
  PhoneNumberEntry = "/phone-entry",

  // On successfull phone entry, page that shows code entry field.
  // If code has been entered successfully, cognito user is created
  // and post-auth cognito function triggers to create the visitor.
  PhoneCodeConfirmation = "/phone-confirmation",

  // Reset password paths
  ResetPasswordEmailEntry = "/reset-password-email",
  ResetPasswordPasswordEntry = "/reset-password",
  PasswordSuccessfullyReset = "/password-successfully-reset"
}

export type SignInValues = {
  email: string;
  password: string;
};

export type CreateVisitorInput = {
  id?: string | null;
  owner?: string | null;
  firstName: string;
  lastName: string;
  phoneNumber?: string | null;
  email: string;
  createdAt?: string | null;
  updatedAt?: string | null;
  signUpOrgID?: string | null;
  signUpSiteID?: string | null;
  stripeCustomerID?: string | null;
  isPhoneVerified?: boolean | null;
  _version?: number | null;
  __typename: string;
  _lastChangedAt?: number | null;
};

export type UpdateVisitorInput = {
  id: string | null;
  _version?: number | null;
  dateOfBirth?: string;
};

export enum Model {
  Site = "site",
  Org = "org"
}

export type Customer = {
  __typename: "Customer";
  id: string;
  pk: string;
  sk: string;
  email: string;
  phoneNumber?: string | null;
  visitorID: string;
  createdAt: string;
  lastUpdatedAt?: string;
  org_order_number?: number;
  org_order_spend?: number;
  org_booking_number?: number;
  org_booking_spend?: number;
  org_total_spend?: number;
  site_order_number?: number;
  site_order_spend?: number;
  site_booking_number?: number;
  site_booking_spend?: number;
  site_total_spend?: number;
  activity_order_number?: number;
  activity_order_spend?: number;
  activity_booking_number?: number;
  activity_booking_spend?: number;
  activity_total_spend?: number;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  updatedAt: string;
  "sk#org_total_spend"?: string;
  "sk#site_total_spend"?: string;
  "sk#lastUpdatedAt"?: string;
  "sk#siteByLastUpdatedAt"?: string;
};

export type CustomerValues = Omit<Customer, "id" | "pk" | "sk">;

type OrderConnection = {
  items: Order[];
  nextToken?: string | null;
};

type SessionBookingsConnection = {
  items: SessionBooking[];
  nextToken?: string | null;
};

type NotificationConnection = {
  items: Notification[];
  nextToken?: string | null;
};

type PlanConnection = {
  items: Plan[];
  nextToken?: string | null;
};

export type Visitor = {
  _deleted?: boolean;
  _lastChangedAt: string;
  _version: number;
  bookedSessions?: SessionBookingsConnection;
  createdAt: string;
  dateOfBirth?: string;
  email: string;
  firstName: string;
  id: string;
  isPhoneVerified?: boolean;
  lastName: string;
  notifications?: NotificationConnection;
  orders: OrderConnection;
  owner?: string;
  phoneNumber?: string;
  plans?: PlanConnection;
  signUpOrgID?: string;
  signUpSiteID?: string;
  stripeCustomerID?: string;
  updatedAt: string;
};

type VisitorConnection = {
  items: Visitor[];
};

export type VisitorByOwner = {
  visitorByOwner: VisitorConnection;
};

export enum AuthErrorMessage {
  NoUserFound = "No user found",
  UserNotVerified = "User not verified"
}

export type UpdateVisitorMutation = {
  updateVisitor: {
    id: string;
  };
};
