/**
 * Returns the search query param for a query.
 * @param query The name of the search query.
 */
export const getSearchParam = (query: string) => {
  if (typeof window === "undefined") return "";

  return new URLSearchParams(window.location.search).get(query) || "";
};

/**
 * Removed a search query param from the URL.
 */
export const removeSearchParam = (query: string) => {
  if (typeof window === "undefined") return;

  const params = new URLSearchParams(window.location.search);
  params.delete(query);

  window.history.replaceState(
    {},
    "",
    `${window.location.pathname}?${params.toString()}`
  );
};
