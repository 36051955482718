import { Center } from "@cloudline/cloudline-styled-components";

type Props = {
  icon: JSX.Element;
  /** For tooltip */
  title?: string;
};

/** Render an Icon. React-icons by default doesn't center the icon annoyingly. Also can't dynamically import unfortunately. */
export const Icon = ({ icon, title }: Props) => (
  <Center title={title}>{icon}</Center>
);
