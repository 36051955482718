import {
  ModelSessionBookingConnection,
  Session
} from "features/sessions/types";
import { Tag } from "features/site";
import {
  CurrencyIso,
  Location,
  MapSettings,
  OpeningHours,
  OpeningHoursVisibility,
  S3Object,
  State,
  Status
} from "types";

export type Activity = {
  _deleted?: boolean | null;
  _version: number;
  activity_info?: string;
  coverImage?: S3Object;
  customMapIcon?: S3Object;
  customMapIconColor?: string;
  customStatusMessages?: CustomStatusMessages;
  description?: string;
  id: string;
  isServiceChargeEnabledVisitor?: boolean;
  isVATDisplayed?: boolean;
  location: Location;
  name: string;
  notifications?: NotificationSettings;
  openingHours?: OpeningHours;
  openingHoursVisibility?: OpeningHoursVisibility;
  orderLogistics?: OrderLogistics[];
  sessionLogistics?: SessionLogistic[];
  pricing?: PricingType;
  redemption_info?: string;
  serviceChargeFlatFee?: number;
  serviceChargePercentage?: number;
  settings?: ActivitySettings;
  siteID: string;
  state: State;
  status: Status;
  stripePaymentIsDisabled?: boolean;
  tags?: ModelActivityTagConnectorConnection;
  type: ActivityType;
  waitTimes?: WaitTimes;
};

export type ModelSessionConnection = {
  items: Session[];
  nextToken?: string | null;
  startedAt?: string | null;
};

export type CustomStatusMessages = {
  customClosedMessage?: string;
  customOpenMessage?: string;
  customUnavailableMessage?: string;
};

export type OrderLogistics = {
  collectionInformation?: string;
  deliverySettings?: DeliverySetting[];
  isActive?: boolean;
  name?: string;
  type?: OrderLogisticsType;
};

export type SessionLogistic = {
  name?: string;
  link?: string;
  description?: string;
  verificationChecks?: string[];
  isActive?: boolean;
};

export type DeliverySetting = {
  customerQuestion: string;
  inputType: InputType;
  isActive: boolean;
  options?: string[];
  staffInstruction: string;
};

export type ActivitySettings = {
  eventTimes?: EventTimes;
  map?: MapSettings;
  notifications?: NotificationSettings;
};

export type WaitTimes = {
  busyMessage?: string;
  busyOverride?: boolean;
  enableSplitDelivery?: boolean;
  enabled: boolean;
  prepTimeGenerationAnswers?: [number, number][];
  preparationTimes?: WaitTimePrepTimes;
  visibility: WaitTimesVisibility;
};

export type WaitTimePrepTimes = {
  kitchenBusyTime?: number;
  kitchenTime?: number;
  orderMinTime?: number;
  serveryBusyTime?: number;
  serveryTime?: number;
};

export type WaitTimesVisibility = {
  activity?: boolean;
  checkout?: boolean;
  explore?: boolean;
};

export enum WaitTimeVisibility {
  Activity = "activity",
  Checkout = "checkout",
  Explore = "explore"
}

export type NotificationSettings = {
  RESTAURANT?: RestaurantNotificationStates;
  EVENT?: EventNotificationStates;
  SESSION?: SessionNotificationSettings;
};

export type SessionNotificationSettings = {
  USER_BOOKED?: NotificationSettingStateObject;
  USER_CANCELLED?: NotificationSettingStateObject;
  SESSION_CANCELLED?: NotificationSettingStateObject;
  SESSION_SOON?: NotificationSettingStateObject;
  SESSION_NOW?: NotificationSettingStateObject;
};

export type EventNotificationStates = {
  EVENT_SOON?: NotificationSettingStateObject;
};

export type RestaurantNotificationStates = {
  ACCEPTED_TO_IN_PROGRESS?: NotificationSettingStateObject;
  ACCEPTED_TO_READY?: NotificationSettingStateObject;
  BASKET_TO_REQUESTED?: NotificationSettingStateObject;
  IN_PROGRESS_TO_PREPARED?: NotificationSettingStateObject;
  PREPARED_TO_READY?: NotificationSettingStateObject;
  READY_TO_COMPLETE?: NotificationSettingStateObject;
  REQUESTED_TO_ACCEPTED?: NotificationSettingStateObject;
  REQUESTED_TO_IN_PROGRESS?: NotificationSettingStateObject;
};

export type NotificationSettingStateObject = {
  active: boolean;
  deliveryMedium: NotificationDeliveryMedium;
  notificationMessage: NotificationMessage;
};

export type NotificationDeliveryMedium = {
  email: boolean;
  sms: boolean;
  webPush: boolean;
};

export type NotificationMessage = {
  email?: EmailMessage;
  fieldVariable?: string;
  inApp?: AppMessage;
  sms?: SmsMessage;
};

export type SmsMessage = {
  text?: string;
};

export type AppMessage = {
  longText?: string;
  shortText: string;
  title: string;
};

export type EmailMessage = {
  html?: string;
  subject: string;
  text?: string;
};

export type EventTimes = {
  friday?: EventTimeRange[];
  monday?: EventTimeRange[];
  saturday?: EventTimeRange[];
  sunday?: EventTimeRange[];
  thursday?: EventTimeRange[];
  tuesday?: EventTimeRange[];
  wednesday?: EventTimeRange[];
};

export type EventTime = keyof EventTimes;

export type EventTimeRange = {
  closed: boolean;
  end: string;
  isHighPriority?: boolean;
  start: string;
};

export type ModelActivityTagConnectorConnection = {
  items: ActivityTagConnector[];
  nextToken?: string;
  startedAt?: string;
};

export type ActivityTagConnector = {
  _deleted?: boolean;
  _lastChangedAt: string;
  _version: number;
  activity: Activity;
  activityID: string;
  createdAt: string;
  id: string;
  tag: Tag;
  tagID: string;
  updatedAt: string;
};

export enum ActivityType {
  All = "ALL",
  Restaurant = "RESTAURANT",
  Session = "SESSION",
  Event = "EVENT",
  Poi = "POI"
}

export const ActivityTypeString = {
  [ActivityType.All]: "All",
  [ActivityType.Restaurant]: "Restaurant",
  [ActivityType.Session]: "Activity",
  [ActivityType.Event]: "Event",
  [ActivityType.Poi]: "Point of Interest"
};

export enum PricingType {
  Free = "FREE",
  Paid = "PAID",
  Prepaid = "PREPAID",
  Varied = "VARIED"
}

export enum OrderLogisticsType {
  Collection = "COLLECTION",
  Delivery = "DELIVERY"
}

export enum InputType {
  Number = "NUMBER",
  Select = "SELECT",
  String = "STRING"
}

/** Translated types to common English. */
export const inputTypeMap = {
  [InputType.String]: "Text",
  [InputType.Number]: "Number",
  [InputType.Select]: "Option"
} as const;

export type OnCreateActivitySubscription = {
  onCreateActivity?: {
    siteID?: string;
  };
};

export type OnUpdateActivitySubscription = {
  onUpdateActivity?: {
    id?: string;
    siteID?: string;
  };
};

export type ListActivitiesQuery = {
  listActivitiesByStatusByState?: {
    items: Array<{
      id: string;
      siteID: string;
      name: string;
      status: Status;
      state: State;
      type: ActivityType;
      customMapIconColor?: string;
      _version: number;
      _deleted: boolean;
      coverImage?: {
        bucket: string;
        region: string;
        key: string;
      };
      location: { lat: number; lon: number };
      customMapIcon: {
        bucket: string;
        region: string;
        key: string;
      };
      stripePaymentIsDisabled?: boolean;
    }>;
  };
};

export enum ActivityRoutes {
  Activities = "/today/activities",
  Events = "/today/events",
  Bookable = "/today/bookable",
  Food = "/today/food"
}

export type ListSessionsQuery = {
  getActivity: {
    sessions: {
      items: Array<{
        id: string;
        activityID: string;
        start: string;
        end: string;
        size: number;
        createdAt: string;
        color: string;
        price: number;
        isCancelled: boolean;
        attendees: ModelSessionBookingConnection;
        _version: number;
        _deleted: boolean;
      }>;
      nextToken?: string | null;
    };
  };
};

export type Category = {
  _deleted?: boolean;
  _lastChangedAt: string;
  _version: number;
  activityID: string;
  createdAt: string;
  id: string;
  isHidden?: boolean;
  menuItems?: ModelMenuItemConnection;
  name: string;
  order: number;
  updatedAt: string;
};

export type ModelMenuItemConnection = {
  items: MenuItem[];
  nextToken?: string;
  startedAt?: string;
};

export type MenuItem = {
  _deleted?: boolean;
  _lastChangedAt: string;
  _version: number;
  activityID: string;
  addons?: string[];
  category?: Category;
  categoryID: string;
  createdAt: string;
  currencyISO: CurrencyIso;
  description?: string;
  hasAllergens?: boolean;
  id: string;
  image?: S3Object;
  isAddon?: boolean;
  isAgeRestricted?: boolean;
  isPublished?: boolean;
  isShownInVisitorMenu?: boolean;
  lastUpdatedBy?: string;
  name: string;
  outOfStock?: boolean;
  parentMenuItems?: string[];
  price?: number;
  settings?: MenuItemSettings;
  state?: State;
  taxRateID?: string;
  updatedAt: string;
  variantDescription?: string;
  variantTitle?: string;
  variantType?: string;
};

export type OnCreateCategorySubscription = {
  onCreateCategory?: {
    activityID: string;
  };
};

export type OnUpdateCategorySubscription = {
  onUpdateCategory?: {
    id: string;
    activityID: string;
  };
};

export type OnCreateMenuItemSubscription = {
  onCreateMenuItem?: {
    activityID: string;
    categoryID: string;
  };
};

export type OnUpdateMenuItemSubscription = {
  onUpdateMenuItem?: {
    id: string;
    activityID: string;
    categoryID: string;
  };
};

export type MenuItemSettings = {
  allergenInfo?: AllergenInfo;
  dietInfo?: DietInfo;
  prepInfo?: PrepInfo;
};

export type PrepInfo = {
  comment?: string;
  prepType?: PrepType;
};

export type DietInfo = {
  comment?: string;
  suitableFor?: Diet[];
  suitableForComment?: string;
};

export type AllergenInfo = {
  comment?: string;
  contains?: Allergen[];
  containsComment?: string;
  mayContain?: Allergen[];
  mayContainComment?: string;
};

export type Allergen = {
  comment?: string;
  type?: AllergenType;
};

export type Diet = {
  comment?: string;
  type?: DietType;
};

export enum PrepType {
  Kitchen = "KITCHEN",
  Servery = "SERVERY"
}

export enum AllergenType {
  Celery = "CELERY",
  Cereals = "CEREALS",
  Crustaceans = "CRUSTACEANS",
  Eggs = "EGGS",
  Fish = "FISH",
  Lupin = "LUPIN",
  Milk = "MILK",
  Molluscs = "MOLLUSCS",
  Mustard = "MUSTARD",
  Peanuts = "PEANUTS",
  Seasameseeds = "SEASAMESEEDS",
  Soybeans = "SOYBEANS",
  Sulphites = "SULPHITES",
  Treenuts = "TREENUTS"
}

export enum DietType {
  Diabetic = "DIABETIC",
  Glutenfree = "GLUTENFREE",
  Halal = "HALAL",
  Kosher = "KOSHER",
  Lactoseintolerant = "LACTOSEINTOLERANT",
  Vegan = "VEGAN",
  Vegetarian = "VEGETARIAN"
}

export type ListCategoriesQuery = {
  getActivity?: {
    categories?: {
      items?: {
        id: string;
        name: string;
        order: number;
        activityID: string;
        _deleted: boolean | null;
        menuItems?: {
          items?: {
            id: string;
            state: State;
            variantType: string;
            isAddon: boolean;
            isShownInVisitorMenu: boolean;
          }[];
        };
      }[];
    };
  };
};

export type ListMenuItemsQuery = {
  getActivity?: {
    menuItems?: {
      items?: {
        id: string;
        name: string;
        image: S3Object;
        description: string;
        state: State;
        activityID: string;
        categoryID: string;
        price: number;
        currencyISO: CurrencyIso;
        outOfStock: boolean;
        taxRateID: string;
        variantType: string;
        variantTitle: string;
        variantDescription: string;
        isAddon: boolean;
        addons: string[];
        hasAllergens: boolean;
        isShownInVisitorMenu: boolean;
        isPublished: boolean;
        lastUpdatedBy: string;
        settings: MenuItemSettings;
        category?: {
          id: string;
          name: string;
        };
        _version: number;
        _deleted: boolean;
      }[];
      nextToken?: string | null;
    };
  };
};
