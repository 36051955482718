import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { useTags } from "features/activities/hooks/useTags";
import {
  GetSiteByCustomDomainQuery,
  GetSiteBySubDomainQuery,
  Site
} from "features/site";
import { getAuthMode } from "utils/getAuthMode";
import { requestWithErrHandler } from "utils/requestWithErrHandler";
import { getSubDomain } from "../utils/getSubDomain";
import { normaliseTags } from "../utils/normaliseTags";

export const fetchSite = async () => {
  const authMode = await getAuthMode();
  const { subDomain, customDomain } = getSubDomain(window.location.host);

  let site: Site = {} as Site;

  // Attempt to fetch the site by both subDomain and customDomain.
  // Custom domains take precedence over subdomains. If no site is found
  // by customDomain, then try subdomain.
  if (customDomain) {
    const siteByCustomDomain = await requestWithErrHandler<
      GetSiteByCustomDomainQuery,
      { customDomain: string }
    >(getSiteByCustomDomain, { customDomain }, authMode);

    site = siteByCustomDomain?.data?.getSiteByCustomDomain?.items?.[0] as Site;
  }

  if (!site?.id && subDomain) {
    const siteBySubDomain = await requestWithErrHandler<
      GetSiteBySubDomainQuery,
      { subDomain: string }
    >(getSiteBySubDomain, { subDomain }, authMode);

    site = siteBySubDomain.data?.getSiteBySubDomain?.items?.[0] as Site;
  }

  /** Set normalised Tag state. */
  useTags.setState(normaliseTags(site.tags?.items || []));

  return site;
};

export const useSite = (options: UseQueryOptions<Site> = {}) =>
  useQuery<Site, unknown>(["site"], () => fetchSite(), {
    ...options
  });

const SITE_FRAGMENT = /* GraphQL */ `
  fragment SiteFragment on Site {
    id
    organizationID
    name
    primaryColor
    secondaryColor
    location {
      lat
      lon
    }
    customMap {
      bucket
      region
      key
    }
    customMapCoords
    timeOffset
    subDomain
    customDomain
    mapEnabled
    mapStyle
    customTagsInMapIsActive
    connectedStripeAccountID
    stripeSetUpIsComplete
    authRequired
    manifestSettings {
      name
      short_name
      start_url
      display
      background_color
      theme_color
      orientation
      icons {
        src
        sizes
      }
    }
    logoImage {
      bucket
      region
      key
    }
    featureConfiguration {
      id
      siteID
      noticesEnabled
      restaurantActivitiesEnabled
      restaurantActivitesSMSEnabled
      sessionActivitiesEnabled
      sessionActivitiesSMSEnabled
      eventActivitiesEnabled
      appletsEnabled
      crmEnabled
      mapEnabled
      apiEnabled
      faqEnabled
      createdAt
      updatedAt
    }
    taxRates {
      items {
        id
        siteID
        createdAt
        createdBy
        created
        object
        active
        display_name
        inclusive
        percentage
        country
        state
        jurisdiction
        description
        livemode
        tax_type
        _version
        _deleted
        _lastChangedAt
        updatedAt
      }
      nextToken
    }
    tags {
      items {
        id
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        activities {
          items {
            _deleted
            activityID
            activity {
              state
              _deleted
            }
          }
        }
      }
    }
    customExplorePages
    openingHours {
      monday {
        closed
        start
        end
      }
      tuesday {
        closed
        start
        end
      }
      wednesday {
        closed
        start
        end
      }
      thursday {
        closed
        start
        end
      }
      friday {
        closed
        start
        end
      }
      saturday {
        closed
        start
        end
      }
      sunday {
        closed
        start
        end
      }
    }
    settings {
      logoStyle {
        width
        height
        margin
        padding
      }
      map {
        zoomLevel
        bearing
        bounds
      }
    }
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    oneSignalAppID
    oneSignalAppSafariWebID
    organization {
      id
      name
      isActive
      isDemo
      onboardingIsComplete
      connectedStripeAccountID
      stripeSetUpIsComplete
      cardNetworkFee
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
    embeds {
      items {
        _deleted
        _version
        icon {
          bucket
          key
          region
        }
        id
        isEnabled
        name
        order
        siteID
        url
      }
    }
  }
`;

export const getSiteByCustomDomain = /* GraphQL */ `
  query GetSiteByCustomDomain(
    $customDomain: String!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSiteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSiteByCustomDomain(
      customDomain: $customDomain
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ...SiteFragment
      }
      nextToken
    }
  }
  ${SITE_FRAGMENT}
`;

export const getSiteBySubDomain = /* GraphQL */ `
  query GetSiteBySubDomain(
    $subDomain: String!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSiteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSiteBySubDomain(
      subDomain: $subDomain
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ...SiteFragment
      }
      nextToken
    }
  }
  ${SITE_FRAGMENT}
`;
