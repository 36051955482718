/**
 * Getting the subdomain and (potential) customDomain from a URL to fetch the correct site.
 * Passing host as argument for easier functional tests.
 * ```
 * # Example domains:
 * hobbledownheath.cloudlineapp.com
 * dev-hobbledownheath.cloudlineapp.com
 * this is a test push.
 * # Example custom domains:
 * gripped.app
 * hobbledown.app
 * epsom.hobbledown.app
 * dev-epsom.hobbledown.app
 * ```
 */
export function getSubDomain(host: string): {
  subDomain: string;
  customDomain: string;
} {
  // Development subdomain and Temporary support for default redirect from hobbledown.app to Hobbledown Epsom
  if (
    host.includes("ngrok") ||
    host.split(":").includes("localhost") ||
    host.includes("cl-visitor") // Support for vercel preview links
  ) {
    return {
      subDomain: "hobbledownepsom",
      customDomain: "dev-epsom.hobbledown.app"
    };
  }

  // Split the hostname by periods and hyphens and removing dev string, and returning the first part.
  // e.g dev-hobbledown.cloudlineapp.com => ['hobbledown', 'cloudlineapp', 'com'] => "hobbledown"
  const subDomain = host.split(/[., -]+/).filter(str => str !== "dev")[0];

  // For custom domains, we simply return the host.
  return { subDomain, customDomain: host };
}
