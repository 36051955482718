import { useActivity } from "features/activities/hooks/useActivity";
import Head from "next/head";
import { useRouter } from "next/router";
import { capitalize } from "utils/capitalize";
import { useSite } from "../../features/site/hooks/useSite";

/** Head component used across the app. Contains title and metadata. */
export const AppHead = () => {
  const { pathname, query } = useRouter();
  const { activityId, id, tab, sessionId, type } = query as {
    activityId: string | undefined;
    id: string | undefined;
    sessionId: string | undefined;
    tab: string | undefined;
    type: string | undefined;
  };
  const { data: { name: siteName } = {} } = useSite();
  const { name: activityName } = useActivity(activityId);

  /** Title for receipt. Need to remove the `s` from the tab and display id. */
  const receipt = `${tab?.slice(0, -1)} #${id?.substring(0, 4)}`;
  /** Title for session. Activity name and session id */
  const session = `${activityName} #${sessionId?.substring(0, 4)}`;

  const titles: Record<string, string | undefined> = {
    "/": "Today",
    "/account": "Account",
    "/activities/[activityId]": activityName,
    "/activities/[activityId]/[sessionId]": session,
    "/checkout": "Checkout",
    "/help": "FAQs",
    "/history": "History",
    "/history/[tab]": tab,
    "/history/[tab]/[id]": receipt,
    "/login-success": "Success",
    "/login": "Login",
    "/map": "Map",
    "/my-details": "My Details",
    "/notifications": "Notifications",
    "/paymentResult": "Success",
    "/phone-confirmation": "Enter Code",
    "/phone-entry": "Phone Number",
    "/purchases": "Purchases",
    "/reset-password-email": "Reset Password",
    "/reset-password": "Reset Password",
    "/search": "Search",
    "/signup": "Sign Up",
    "/today/[type]": type,
    "/whats-on/[tab]": tab
  };

  const currentPath = titles[pathname] || "";

  return (
    <Head>
      <title key="title">
        {currentPath ? `${capitalize(currentPath)} | ` : ""} {siteName}
      </title>
      <meta name="description" content="Cloudline visitor app." />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1"
      />
      <link
        rel="manifest"
        id="manifest"
        href="/manifest.json"
        crossOrigin="anonymous"
      />
    </Head>
  );
};
