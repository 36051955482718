import { SortDirection } from "types";

export interface Data<T> {
  ids: string[];
  entities: { [id: string]: T };
}

/** Normalise an array and return an object with ids and entities. */
export function normalise<T extends { id: string }>(
  array: T[],
  sortKey?: keyof T,
  sortDirection?: SortDirection
): Data<T> {
  let data = array;

  if (sortKey) {
    data = array.sort((a, b) => {
      if (sortDirection === SortDirection.Desc)
        return a[sortKey] < b[sortKey] ? 1 : -1;

      return a[sortKey] > b[sortKey] ? 1 : -1;
    });
  }

  return data.reduce(
    (acc: Data<T>, item) => {
      acc.ids.push(item.id);
      acc.entities[item.id] = item;

      return acc;
    },
    { ids: [], entities: {} }
  );
}
