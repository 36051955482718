import { Box, Show } from "@cloudline/cloudline-styled-components";
import { useVerifyUser } from "features/auth/hooks/useVerifyUser";
import { useVisitor } from "features/auth/hooks/useVisitor";
import { useActivitySubscriptions } from "features/subscriptions/hooks/useActivitySubscriptions";
import { useNotificationSubscriptions } from "features/subscriptions/hooks/useNotificationSubscriptions";
import { useOrderSubscriptions } from "features/subscriptions/hooks/useOrderSubscriptions";
import { useSessionSubscriptions } from "features/subscriptions/hooks/useSessionSubscriptions";
import { useMobile } from "hooks/useMobile";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useSite } from "../../features/site/hooks/useSite";
import { Navbar } from "../Navbar";
import { UseOnMobile } from "../UseOnMobile";

type Props = {
  children: React.ReactNode;
};

const Layout = ({ children }: Props) => {
  const { data: { authRequired } = {}, isLoading: isFetchingSite } = useSite();
  const { data: visitor, isLoading: isFetchingVisitor } = useVisitor();
  const { replace } = useRouter();

  useActivitySubscriptions();
  useOrderSubscriptions();
  useSessionSubscriptions();
  useNotificationSubscriptions();
  useVerifyUser();

  useEffect(() => {
    if (isFetchingSite || isFetchingVisitor) return;
    if (authRequired && !visitor?.id) replace("/login");
  }, [authRequired, replace, visitor?.id, isFetchingSite, isFetchingVisitor]);

  return (
    <Show when={useMobile()} fallback={<UseOnMobile />}>
      <Box h="100%" minH="100%">
        {children}
        <Navbar />
      </Box>
    </Show>
  );
};

export default dynamic(() => Promise.resolve(Layout), {
  ssr: false
});
