import { useSite } from "features/site/hooks/useSite";
import { memo, useEffect } from "react";
import { useOneSignal } from "../hooks/useOneSignal";

/**
 * Component that initialises OneSignal - can't initialise in app.tsx
 * as site data from react query is not available at that point.
 * @returns null
 */
const OneSignalInitialiser = () => {
  const { data: site } = useSite();

  const { initialiseOneSignal } = useOneSignal();

  useEffect(() => {
    if (
      site?.id &&
      site.oneSignalAppID &&
      site.oneSignalAppSafariWebID &&
      site.subDomain
    ) {
      // Only supporting one domain per site.
      if (window.location.origin.includes(site.subDomain)) {
        initialiseOneSignal(site.oneSignalAppID, site.oneSignalAppSafariWebID);
      } else {
        console.log("Site domain not supported for OneSignal.");
      }
    }
  }, [
    site?.id,
    site?.oneSignalAppID,
    site?.oneSignalAppSafariWebID,
    site?.subDomain,
    initialiseOneSignal
  ]);

  return null;
};

export default memo(OneSignalInitialiser);
