import { GeneralConnectionStatus, useNetworkStatus } from "features/network";
import { Subscription } from "features/subscriptions/types";
import { useEffect } from "react";

/** Hook that unsubscribes to subscriptions when the network goes offline. */
export const useOfflineUnsubscribe = (
  //!This type for now until Amplify add better typing in v6: https://github.com/aws-amplify/amplify-js/issues/11113
  subscriptions: (Subscription | undefined)[]
) => {
  const {
    connection: { status }
  } = useNetworkStatus();

  useEffect(() => {
    if (status === GeneralConnectionStatus.Offline) {
      /** Unsubscribing from each subscription */
      subscriptions?.forEach(sub => sub?.unsubscribe());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);
};
