import { ActivityType } from "features/activities";
import { State } from "types";
import { Tag } from "../types";

/** Normalise Tags and save a reference of all linked Activities. */
export const normaliseTags = (tags: Tag[]) => {
  const activeTags = tags.filter(tag => !tag._deleted);

  return activeTags.reduce(
    (acc: { tags: string[]; activities: Record<string, string[]> }, tag) => {
      /** Filter out any Draft or deleted activities */
      const activities =
        tag.activities?.items.filter(
          ({ _deleted, activity }) =>
            !_deleted &&
            activity.state === State.Published &&
            !activity._deleted
        ) || [];

      if (activities.length === 0) return acc;

      acc.tags.push(tag.name);
      acc.activities[tag.name] = activities.map(({ activityID }) => activityID);
      acc.activities[ActivityType.All].push(
        ...activities.map(({ activityID }) => activityID)
      );

      /** Remove any duplicates */
      acc.activities[ActivityType.All] = [
        ...new Set(acc.activities[ActivityType.All])
      ];

      return acc;
    },
    {
      tags: [ActivityType.All],
      activities: {
        [ActivityType.All]: []
      }
    }
  );
};
