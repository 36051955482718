import { Box, Show } from "@cloudline/cloudline-styled-components";
import { motion } from "framer-motion";
import NextImage, { ImageProps } from "next/image";
import { useState } from "react";
import { IoMdImages } from "react-icons/io";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";
import { Icon } from "./Icon";

const NoImage = styled.div<{ h: number; w: number }>`
  height: 100%;
  width: 100%;
  max-width: ${({ w }) => w}px;
  max-height: ${({ h }) => h}px;
  min-width: ${({ w }) => w}px;
  min-height: ${({ h }) => h}px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  border: 2px dashed ${({ theme }) => theme.color.grey[500]};
  border-radius: 5px;
  color: ${({ theme }) => theme.color.grey[700]};

  span {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

interface Props extends Omit<ImageProps, "src"> {
  s3Key: string;
  circle?: boolean;
  rounded?: boolean;
  borderRadius?: React.CSSProperties["borderRadius"];
  fit?: "cover" | "contain" | "fill";
}

/** Custom Image component using Next/Image but updated to fetch the image and handle loading and fallback. */
export const Image = ({
  s3Key,
  height,
  width,
  style,
  circle,
  rounded = true,
  borderRadius,
  fit = "cover",
  ...props
}: Props) => {
  const image = `https://${process.env.AWS_USER_FILES_S3_BUCKET}.s3.${process.env.REGION}.amazonaws.com/public/${s3Key}`;
  const [isLoaded, setIsLoaded] = useState(false);

  const getBorderRadius = (): React.CSSProperties["borderRadius"] => {
    if (borderRadius) return borderRadius;
    if (circle) return "50%";
    if (rounded === false) return "0px";
    return "4px";
  };

  return (
    <Show
      when={!!image && !!s3Key}
      fallback={
        <NoImage h={height as number} w={width as number}>
          <Icon icon={<IoMdImages size="18px" />} />
        </NoImage>
      }
    >
      <Box position="relative" w={width || "100%"} h={height || "100%"}>
        <Show when={!isLoaded}>
          <Box w={width || "100%"} h={height || "100%"} position="absolute">
            <Skeleton
              height="100%"
              width="100%"
              style={{
                lineHeight: "normal",
                borderRadius: getBorderRadius(),
                zIndex: -1
              }}
            />
          </Box>
        </Show>

        <Box
          h={height || "100%"}
          w={width || "100%"}
          position="absolute"
          as={motion.div}
          animate={{ opacity: isLoaded ? 1 : 0 }}
        >
          <NextImage
            src={image || ""}
            {...props}
            height={height}
            width={width}
            style={{
              borderRadius: getBorderRadius(),
              objectFit: fit,
              ...style
            }}
            onLoad={() => setIsLoaded(true)}
          />
        </Box>
      </Box>
    </Show>
  );
};
