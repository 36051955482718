import { useRouter } from "next/router";
import { useActivities } from "./useActivities";

/** Select a single activity by ID  */
export const useActivity = (id = "") => {
  const { data: { entities = {} } = {} } = useActivities();
  const { activityId } = useRouter().query;

  return entities[id || (activityId as string)] || {};
};
