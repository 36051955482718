import {
  Box,
  Flex,
  Loader,
  Text
} from "@cloudline/cloudline-styled-components";
import { useSite } from "features/site/hooks/useSite";
import NextImage from "next/image";
import { QRCodeSVG } from "qrcode.react";
import ApplePay from "static/apple-pay-logo.svg";
import GooglePay from "static/google-pay-logo.svg";
import styled from "styled-components";
import { Image } from "./Image";
import { PoweredByCloudline } from "./PoweredByCloudline";

const PageContainer = styled.div`
  position: relative;
`;

const ColourBackgroundContainer = styled.div`
  position: fixed;
  display: grid;
  grid-template-columns: 60% 40%;
  height: 100vh;
  width: 100vw;
`;

const ColourBackground = styled.div<{ color: string }>`
  background: ${props => props.color};
  height: 100vh;
`;

const Wrapper = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 100vh;
  width: 100%;
  max-width: 1920px;
  display: grid;
  grid-template-columns: 60% 40%;
  margin: 0 auto;

  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
  }
`;

const LeftContainer = styled.section<{ secondaryColour: string }>`
  background: ${props => props.secondaryColour};
  display: grid;
  place-content: center;

  > div:first-child {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2rem;
  }
`;

const ExploreText = styled.h1<{ primaryColour: string }>`
  max-width: 800px;
  color: ${props => props.primaryColour};
  font-size: 6rem;
  font-weight: 800;
  line-height: 130%;
  margin-bottom: 2.5rem;

  span {
    display: block;
  }

  @media (max-width: 2000px) {
    font-size: 5rem;
  }

  @media (max-width: 1550px) {
    font-size: 4rem;
  }

  @media (max-width: 1200px) {
    font-size: 3rem;
  }
`;

const AppFeatures = styled.ul<{ primaryColour: string }>`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-left: 1.5rem;
  list-style: square;

  li {
    color: ${props => props.primaryColour};
    font-size: 1.7rem;
    font-weight: 500;

    @media (max-width: 2000px) {
      font-size: 1.5rem;
    }

    @media (max-width: 1550px) {
      font-size: 1.3rem;
    }
  }
`;

const PaymentIcons = styled.div`
  margin: 3.5rem 0 3.5rem 1.25rem;
  display: flex;
`;

const IconContainer = styled.div<{ radius?: string }>`
  width: 120px;
  height: 72px;
  margin-right: 1.5rem;
`;

const RightContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;

  > div:first-child {
    height: 100%;
    width: 60%;
    max-width: 500px;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 14rem 0 0;

    @media (max-width: 2000px) {
      width: 50%;
    }

    @media (max-width: 1200px) {
      padding: 4rem 0;
      width: 30%;
    }
  }
`;

const ScanHere = styled.h2`
  color: #000;
  font-size: 4rem;
  font-weight: 600;
  line-height: 100%;
  text-transform: uppercase;
  margin-bottom: 1rem !important;

  @media (max-width: 2000px) {
    font-size: 3rem;
  }

  @media (max-width: 1400px) {
    font-size: 2.5rem;
  }
`;

const NoDownload = styled.p`
  color: #000;
  font-size: 1.5rem;
  font-weight: 400;

  @media (max-width: 1400px) {
    font-size: 1.25rem;
  }

  @media (max-width: 1200px) {
    font-size: 1.5rem;
  }
`;

const QRContainer = styled.div`
  width: 100%;
  margin: 0 0 2rem;
`;

export const UseOnMobile = () => {
  const { data: site, isLoading } = useSite();

  // Also Hobbledown's colours.
  const DEFAULT_COLOURS = {
    primaryColour: "#733b7a",
    secondaryColour: "#f1ead6"
  };

  const { primaryColour, secondaryColour } = {
    primaryColour: site?.primaryColor || DEFAULT_COLOURS.primaryColour,
    secondaryColour: site?.secondaryColor || DEFAULT_COLOURS.secondaryColour
  };

  if (isLoading) return <Loader />;

  return (
    <PageContainer>
      <ColourBackgroundContainer>
        <ColourBackground color={secondaryColour} />
        <ColourBackground color="#fff" />
      </ColourBackgroundContainer>
      <Wrapper>
        <LeftContainer secondaryColour={secondaryColour}>
          <div>
            <Box h={150} w="50%">
              <Image
                alt={site?.name || ""}
                s3Key={site?.logoImage?.key || ""}
                fill
                sizes="100%"
                style={{ objectFit: "contain" }}
              />
            </Box>
            <ExploreText primaryColour={primaryColour}>
              <span>Explore</span>
              <span>{site?.name}</span>
              <span>with our app</span>
            </ExploreText>
            <AppFeatures primaryColour={primaryColour}>
              <li>Order food directly to your table</li>
              <li>Discover and explore with our interactive map</li>
              <li>Book activities during your visit</li>
              <li>No download required, just scan* and use!</li>
            </AppFeatures>
            <PaymentIcons>
              <IconContainer>
                <NextImage
                  src={GooglePay.src}
                  alt="Cloudline Logo"
                  width={115}
                  height={62}
                />
              </IconContainer>
              <IconContainer radius="10px">
                <NextImage
                  src={ApplePay.src}
                  alt="Cloudline Logo"
                  width={115}
                  height={62}
                />
              </IconContainer>
            </PaymentIcons>
            <Text color={primaryColour} weight={500}>
              * You can also access the app by going to this URL on your mobile
              device
            </Text>
          </div>
        </LeftContainer>
        <RightContainer>
          <div>
            <Box mb={10}>
              <Flex column justify="center" align="center">
                <ScanHere>Scan Here</ScanHere>
                <NoDownload>No download required</NoDownload>
              </Flex>
            </Box>
            <QRContainer>
              <QRCodeSVG
                value={window.location.href}
                style={{ width: "100%", height: "100%" }}
              />
            </QRContainer>
            <PoweredByCloudline />
          </div>
        </RightContainer>
      </Wrapper>
    </PageContainer>
  );
};
