import { GraphQLSubscription } from "@aws-amplify/api";
import { useQueries, useQueryClient } from "@tanstack/react-query";
import { API } from "aws-amplify";
import { useVisitor } from "features/auth/hooks/useVisitor";
import { OnCreateNotificationSubscription } from "features/notifications/types";
import { useUnmount } from "hooks/useUnmount";
import posthog from "posthog-js";
import { getAuthMode } from "utils/getAuthMode";
import { useOfflineUnsubscribe } from "./useOfflineUnsubscribe";

export const subscribeToNotificationCreation = async (
  visitorID: string,
  invalidateNotifications: () => void
) => {
  const authMode = await getAuthMode();
  const subscription = API.graphql<
    GraphQLSubscription<OnCreateNotificationSubscription>
  >({
    query: onCreateNotification,
    variables: { visitorID },
    authMode
  }).subscribe({
    next: invalidateNotifications,
    error: error => {
      posthog.capture("Notification Create Subscription error", error);
      console.error(error);
    }
  });

  return subscription;
};

/** Subscribe to Create/Update Notifications */
export const useNotificationSubscriptions = () => {
  const queryClient = useQueryClient();
  const { data: { id: visitorID } = {} } = useVisitor();

  const invalidateNotifications = () => {
    queryClient.invalidateQueries({ queryKey: ["notifications"] });
  };

  const [createSub] = useQueries({
    queries: [
      {
        queryKey: ["onCreateNotification"],
        queryFn: () =>
          subscribeToNotificationCreation(
            visitorID || "",
            invalidateNotifications
          ),
        enabled: !!visitorID,
        retry: true
      }
    ]
  });

  useOfflineUnsubscribe([createSub.data]);

  /**
   * When hook is unmounted unsubscribe from subs and invalidate
   * queries so if the hook mounts again it will resubscribe
   */
  useUnmount(() => {
    createSub?.data?.unsubscribe();
    queryClient.invalidateQueries({
      queryKey: ["onCreateNotification", visitorID]
    });
  });
};

const onCreateNotification = /* GraphQL */ `
  subscription OnCreateNotification($visitorID: ID) {
    onCreateNotification(visitorID: $visitorID) {
      id
    }
  }
`;
