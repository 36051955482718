import { GraphQLSubscription } from "@aws-amplify/api";
import { useQueries, useQueryClient } from "@tanstack/react-query";
import { API } from "aws-amplify";
import {
  OnCreateActivitySubscription,
  OnUpdateActivitySubscription
} from "features/activities/types";
import { useSite } from "features/site/hooks/useSite";
import { useUnmount } from "hooks/useUnmount";
import posthog from "posthog-js";
import { getAuthMode } from "utils/getAuthMode";
import { useOfflineUnsubscribe } from "./useOfflineUnsubscribe";

export const subscribeToActivityCreation = async (
  siteID: string,
  invalidateActivities: (siteId: string) => void
) => {
  const authMode = await getAuthMode();
  const subscription = API.graphql<
    GraphQLSubscription<OnCreateActivitySubscription>
  >({
    query: onCreateActivity,
    variables: { siteID },
    authMode
  }).subscribe({
    next: ({ value: { data } }) =>
      invalidateActivities(data?.onCreateActivity?.siteID || ""),
    error: error => {
      posthog.capture("Activity Creation Subscription error", error);
      console.error(error);
    }
  });

  return subscription;
};

export const subscribeToActivityUpdates = async (
  siteID: string,
  invalidateActivity: () => void
) => {
  const authMode = await getAuthMode();
  const subscription = API.graphql<
    GraphQLSubscription<OnUpdateActivitySubscription>
  >({
    query: onUpdateActivity,
    variables: { siteID },
    authMode
  }).subscribe({
    next: () => invalidateActivity(),
    error: error => {
      posthog.capture("Activity Update Subscription error", error);
      console.error(error);
    }
  });

  return subscription;
};

/** Subscribe to Create/Update Activities */
export const useActivitySubscriptions = () => {
  const queryClient = useQueryClient();
  const { data: { id: siteId } = {} } = useSite();

  const invalidateActivities = () =>
    queryClient.invalidateQueries({ queryKey: ["activities"] });

  const [createSub, updateSub] = useQueries({
    queries: [
      {
        queryKey: ["onCreateActivity", siteId],
        queryFn: () =>
          subscribeToActivityCreation(siteId || "", invalidateActivities),
        enabled: !!siteId,
        retry: true
      },
      {
        queryKey: ["onUpdateActivity", siteId],
        queryFn: () =>
          subscribeToActivityUpdates(siteId || "", invalidateActivities),
        enabled: !!siteId,
        retry: true
      }
    ]
  });

  useOfflineUnsubscribe([createSub.data, updateSub.data]);

  /**
   * When hook is unmounted unsubscribe from subs and invalidate
   * queries so if the hook mounts again it will resubscribe
   */
  useUnmount(() => {
    createSub?.data?.unsubscribe();
    updateSub?.data?.unsubscribe();
    queryClient.invalidateQueries({ queryKey: ["onCreateActivity", siteId] });
    queryClient.invalidateQueries({ queryKey: ["onUpdateActivity", siteId] });
  });
};

const onCreateActivity = /* GraphQL */ `
  subscription OnCreateActivity($siteID: ID) {
    onCreateActivity(siteID: $siteID) {
      siteID
    }
  }
`;

const onUpdateActivity = /* GraphQL */ `
  subscription OnUpdateActivity($siteID: ID) {
    onUpdateActivity(siteID: $siteID) {
      id
      siteID
    }
  }
`;
