import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { useSite } from "features/site/hooks/useSite";
import { getAuthMode } from "utils/getAuthMode";
import { Data, normalise } from "utils/normalise";
import { requestWithErrHandler } from "utils/requestWithErrHandler";
import { Activity, ListActivitiesQuery } from "../types";

export const fetchActivities = async (siteID: string) => {
  const authMode = await getAuthMode();

  const response = await requestWithErrHandler<
    ListActivitiesQuery,
    { siteID: string }
  >(listActivities, { siteID }, authMode);

  const activities = response.data?.listActivitiesByStatusByState?.items.filter(
    ({ _deleted }) => !_deleted
  );

  return normalise<Activity>(
    activities?.sort((a, b) => a.name.localeCompare(b.name)) || []
  );
};

export const useActivities = (
  options: UseQueryOptions<Data<Activity>> = {}
) => {
  const { data: { id } = {} } = useSite();

  return useQuery<Data<Activity>, unknown>(
    ["activities"],
    () => fetchActivities(id || ""),
    { enabled: !!id, ...options }
  );
};

export const listActivities = /* GraphQL */ `
  query listActivitiesByState($siteID: ID!) {
    listActivitiesByStatusByState(
      siteID: $siteID
      filter: { state: { eq: PUBLISHED } }
      limit: 1000
    ) {
      items {
        id
        coverImage {
          bucket
          region
          key
        }
        siteID
        customMapIcon {
          bucket
          region
          key
        }
        customMapIconColor
        customStatusMessages {
          customOpenMessage
          customClosedMessage
          customUnavailableMessage
        }
        waitTimes {
          enabled
          visibility {
            explore
            activity
            checkout
          }
          busyOverride
          busyMessage
          preparationTimes {
            orderMinTime
            serveryTime
            kitchenTime
            serveryBusyTime
            kitchenBusyTime
          }
        }
        name
        activity_info
        redemption_info
        description
        status
        state
        isVATDisplayed
        isServiceChargeEnabledVisitor
        serviceChargePercentage
        serviceChargeFlatFee
        stripePaymentIsDisabled
        location {
          lat
          lon
        }
        tags {
          items {
            id
            tagID
            activityID
            tag {
              id
              name
              _deleted
            }
            _version
            _deleted
          }
        }
        type
        pricing
        _version
        _deleted
        settings {
          eventTimes {
            monday {
              closed
              start
              end
              isHighPriority
            }
            tuesday {
              closed
              start
              end
              isHighPriority
            }
            wednesday {
              closed
              start
              end
              isHighPriority
            }
            thursday {
              closed
              start
              end
              isHighPriority
            }
            friday {
              closed
              start
              end
              isHighPriority
            }
            saturday {
              closed
              start
              end
              isHighPriority
            }
            sunday {
              closed
              start
              end
              isHighPriority
            }
          }
        }
        sessionLogistics {
          name
          link
          description
          verificationChecks
          isActive
        }
        orderLogistics {
          type
          isActive
          deliverySettings {
            inputType
            options
            customerQuestion
            staffInstruction
            isActive
          }
          name
          collectionInformation
        }
        openingHoursVisibility
        openingHours {
          monday {
            closed
            start
            end
          }
          tuesday {
            closed
            start
            end
          }
          wednesday {
            closed
            start
            end
          }
          thursday {
            closed
            start
            end
          }
          friday {
            closed
            start
            end
          }
          saturday {
            closed
            start
            end
          }
          sunday {
            closed
            start
            end
          }
        }
      }
    }
  }
`;
