export enum NetworkTypes {
  UpdateConnectionStatus = "update_connection_status",
  UpdateAWSStatus = "update_aws_status"
}

export enum GeneralConnectionStatus {
  Online = "Online",
  Reconnecting = "Reconnecting",
  Offline = "Offline"
}
