import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";

/** Check if currently viewed on Mobile screen size */
export const useMobile = () => {
  // By default most visitors to the site will be on mobile, so initial state is true.
  const [isMobileView, setIsMobileView] = useState(true);
  useEffect(() => setIsMobileView(isMobile), []);

  return isMobileView;
};
