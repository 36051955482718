import { Box } from "@cloudline/cloudline-styled-components";
import { motion } from "framer-motion";
import { ReactNode, useEffect, useState } from "react";
import ReactDOM from "react-dom";

/** Dom element for Portal. */
export const PortalGateway = () => <div id="portal" />;

/** Renders a child component inside a Portal. */
export const Portal = ({
  children,
  container
}: {
  children: ReactNode;
  container?: HTMLElement | null;
}) => {
  const [domReady, setDomReady] = useState(false);

  useEffect(() => {
    setDomReady(true);
  }, []);

  const [position, setPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);

  // Place the tooltip near the anchor's bottom edge on the screen
  useEffect(() => {
    const bounds = container?.getBoundingClientRect();
    if (bounds) {
      setPosition({
        x: bounds?.x,
        y: bounds?.y
      });
    }
  }, [container]);

  // If the DOM is not ready or the passed container is not valid, return null.
  if (!domReady || container === null) return null;

  return ReactDOM.createPortal(
    <Box
      as={motion.div}
      top={position?.y}
      left={position?.x}
      position="absolute"
      z={9998}
    >
      <>{children}</>
    </Box>,
    document.getElementById("portal")!
  );
};
