import { useRouter } from "next/router";
import { useEffect, useRef } from "react";
import { AuthErrorMessage, AuthRoutePaths } from "../types";
import { useVisitor } from "./useVisitor";

// Hook to check if there's a cognito user for people who haven't finished the sign up process.
export const useVerifyUser = () => {
  const { push } = useRouter();
  const { error } = useVisitor();
  const hasNavigated = useRef(false);

  useEffect(() => {
    // If the user exists but hasn't been verified, we push to phone entry page.
    if (
      error?.message === AuthErrorMessage.UserNotVerified &&
      !hasNavigated.current
    ) {
      console.error("User not verified");
      push(AuthRoutePaths.PhoneNumberEntry);
      hasNavigated.current = true;
    }
  }, [error, push]);
};
