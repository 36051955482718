import { GraphQLSubscription } from "@aws-amplify/api";
import { useQueries, useQueryClient } from "@tanstack/react-query";
import { API } from "aws-amplify";
import { useVisitor } from "features/auth/hooks/useVisitor";
import { OnUpdateOrderSubscription } from "features/orders/types";
import { useUnmount } from "hooks/useUnmount";
import posthog from "posthog-js";
import { getAuthMode } from "utils/getAuthMode";
import { useOfflineUnsubscribe } from "./useOfflineUnsubscribe";

export const subscribeToOrderUpdates = async (
  visitorID: string,
  invalidateOrders: (orderID: string) => void
) => {
  const authMode = await getAuthMode();
  const subscription = API.graphql<
    GraphQLSubscription<OnUpdateOrderSubscription>
  >({
    query: onUpdateVisitorOrder,
    variables: { visitorID },
    authMode
  }).subscribe({
    next: res => {
      const order = res.value.data?.onUpdateVisitorOrder;

      invalidateOrders(order?.id || "");
    },
    error: error => {
      posthog.capture("Order Update Subscription error", error);
      console.error(error);
    }
  });

  return subscription;
};

/** Subscribe to Create/Update Orders */
export const useOrderSubscriptions = () => {
  const queryClient = useQueryClient();
  const { data: { id: visitorID } = {} } = useVisitor();
  // const { activeSubscriptions, removeActiveSubscription } = useSubscriptions();

  const invalidateOrders = (orderID: string) => {
    // This will invalidate the useOrderHistory query in the History and Purchases page.
    queryClient.invalidateQueries({ queryKey: ["historicalOrders"] });

    // This will invalidate the useHistoricalOrder query in the Receipt page.
    queryClient.invalidateQueries({ queryKey: ["historicalOrder", orderID] });
  };

  const [updateSub] = useQueries({
    queries: [
      {
        queryKey: ["onUpdateOrder"],
        queryFn: () =>
          subscribeToOrderUpdates(visitorID || "", invalidateOrders),
        enabled: !!visitorID,
        retry: true
      }
    ]
  });

  useOfflineUnsubscribe([updateSub.data]);

  /**
   * When hook is unmounted unsubscribe from subs and invalidate
   * queries so if the hook mounts again it will resubscribe
   */
  useUnmount(() => {
    updateSub?.data?.unsubscribe();
    queryClient.invalidateQueries({ queryKey: ["onUpdateOrder"] });
  });
};

const onUpdateVisitorOrder = /* GraphQL */ `
  subscription OnUpdateVisitorOrder($visitorID: ID) {
    onUpdateVisitorOrder(visitorID: $visitorID) {
      id
    }
  }
`;
