import { theme } from "@cloudline/cloudline-styled-components";
import { Lato, Manrope } from "next/font/google";
import { createGlobalStyle } from "styled-components";

const manrope = Manrope({
  variable: "--manrope-font",
  subsets: ["latin"],
  display: "fallback"
});

const latoFont = Lato({
  weight: ["100", "300", "400", "700", "900"],
  variable: "--lato-font",
  subsets: ["latin"],
  display: "fallback"
});

export const lato = latoFont.className;

/** Styles that are applied to the entire app. */
export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    ${manrope.style}
  }

  html,
  body,
  body > div:first-child,
  div#__next {
    height: 100%;
    background-color: ${theme.color.grey[100]};
  }

  .radix-themes {
    height: 100%;
  }
  
  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  button {
    border: none;
    outline: none;
    cursor: pointer;
  }

  /* ============================ NProgress ================================ */

  #nprogress {
    pointer-events: none;
  }

  #nprogress .bar {
    position: fixed;
    z-index: 200;
    top: 0;
    left: 0;
    width: 100%;
    height: 3px !important;
    background: ${theme.color.primary[500]} !important;
  }

  /* Fancy blur effect */
  #nprogress .peg {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px #5eb9e0, 0 0 5px #5eb9e0;
    opacity: 1;
    -webkit-transform: rotate(3deg) translate(0px, -4px);
    -ms-transform: rotate(3deg) translate(0px, -4px);
    transform: rotate(3deg) translate(0px, -4px);
  }
`;
