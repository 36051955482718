import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

export type AuthErrorState = {
  error: string | null;
  setError: (error: string) => void;
  clearError: () => void;
};

export const useAuthError = create<AuthErrorState>()(
  persist(
    set => ({
      error: null,
      setError: error => set({ error }),
      clearError: () => set({ error: null })
    }),
    {
      name: "auth-error",
      storage: createJSONStorage(() => localStorage)
    }
  )
);
