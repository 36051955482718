import { Auth } from "aws-amplify";
import { useCallback } from "react";
import OneSignal from "react-onesignal";

/**
 * Hook which initialises OneSignal for push notifications if browser supports it.
 * permissionChangeListener subject to removal if the login function from OneSignal
 * actually saves the external user id as intented. Buggy library.
 * @returns
 */
export const useOneSignal = () => {
  /**
   * Function that runs when the user accepts the notification permission.
   * @param permission boolean
   */
  async function permissionChangeListener(permission: boolean) {
    if (permission) {
      const user = await Auth.currentUserInfo();

      if (user.username) {
        OneSignal.login(user.username);
      }
      OneSignal.Notifications.removeEventListener(
        "permissionChange",
        permissionChangeListener
      );
    }
  }

  const initialiseOneSignal = useCallback(
    async (appId: string, safari_web_id: string) => {
      const isSupported = OneSignal.Notifications.isPushSupported();

      if (isSupported) {
        await OneSignal.init({
          appId,
          safari_web_id,
          allowLocalhostAsSecureOrigin: process.env.NODE_ENV === "development",
          serviceWorkerParam: {
            scope: "/js/push/onesignal/"
          },
          serviceWorkerPath: "./js/push/onesignal/OneSignalSDKWorker.js"
        });
        console.log("initialised OneSignal");
        OneSignal.Notifications.setDefaultUrl(
          `${window.location.href}/purchases`
        );

        OneSignal.Notifications.addEventListener(
          "permissionChange",
          permissionChangeListener
        );
      } else {
        console.log("Push notifications are not supported on this browser");
      }
    },
    []
  );

  return {
    initialiseOneSignal
  };
};
