import { theme } from "@cloudline/cloudline-styled-components";
import Image from "next/image";
import CloudlineLogo from "static/CloudlineLogo.png";
import styled from "styled-components";

const Container = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: ${theme.color.grey[700]};
  cursor: pointer;
  font-size: 12px;
  svg {
    path {
      fill: ${theme.color.grey[700]};
    }
  }
`;

// Component to show the `Powered by Cloudline` logo
export const PoweredByCloudline = () => (
  <Container
    data-ph="powered by cloudline link"
    onClick={() => window.open("https://www.cloudlineapp.com/")}
  >
    Powered by{" "}
    <Image
      src={CloudlineLogo.src}
      alt="Cloudline Logo"
      width={70}
      height={20}
    />
  </Container>
);
