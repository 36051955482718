import { create } from "zustand";
import { GeneralConnectionStatus } from "../types";

interface NetworkStatusState {
  aws: {
    status: GeneralConnectionStatus;
    message: string | null;
  };
  connection: {
    status: GeneralConnectionStatus;
    message: string | null;
  };
  setAwsStatus: (
    status: GeneralConnectionStatus,
    message: string | null
  ) => void;
  setConnectionStatus: (
    status: GeneralConnectionStatus,
    message: string | null
  ) => void;
}

/** Hook containing information on the current network status */
export const useNetworkStatus = create<NetworkStatusState>(set => ({
  aws: {
    status: GeneralConnectionStatus.Online,
    message: null
  },
  connection: {
    status: GeneralConnectionStatus.Online,
    message: null
  },
  setAwsStatus: (status, message) => set(() => ({ aws: { status, message } })),
  setConnectionStatus: (status, message) =>
    set(() => ({ connection: { status, message } }))
}));
