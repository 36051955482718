import { theme } from "@cloudline/cloudline-styled-components";
import { Theme } from "@radix-ui/themes";
import {
  Hydrate,
  QueryClient,
  QueryClientProvider
} from "@tanstack/react-query";
import "animate.css";
import { Amplify, Hub } from "aws-amplify";
import { AppHead } from "components/Layouts/AppHead";
import { Layouts } from "components/Layouts/index";
import { PortalGateway } from "components/Portal";
import { useAuthError } from "features/auth/hooks/useAuthError";
import OneSignalInitialiser from "features/notifications/components/OneSignalInitialiser";
import { useRouter } from "next/router";
import Script from "next/script";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { useEffect, useState } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import { ThemeProvider } from "styled-components";
import { GlobalStyle } from "styles/globals";
import "styles/globals.css";
import { MyAppProps } from "types/index";
import "@radix-ui/themes/styles.css"; // <-- !: Need to import this after globals.css

const awsExports = {
  aws_project_region: process.env.REGION,
  aws_cognito_identity_pool_id: process.env.AWS_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.AWS_USER_POOLS_WEB_CLIENT_ID,
  aws_appsync_graphqlEndpoint: process.env.AWS_APPSYNC_GRAPHQLENDPOINT,
  aws_appsync_region: process.env.AWS_APPSYNC_REGION,
  aws_appsync_authenticationType: process.env.AWS_APPSYNC_AUTHENTICATION_TYPE,
  aws_appsync_apiKey: process.env.AWS_APPSYNC_APIKEY,
  aws_user_files_s3_bucket: process.env.AWS_USER_FILES_S3_BUCKET,
  aws_user_files_s3_bucket_region: process.env.AWS_USER_FILES_S3_BUCKET_REGION,
  aws_mobile_analytics_app_id: process.env.AWS_MOBILE_ANALYTICS_APP_ID,
  aws_mobile_analytics_app_region: process.env.AWS_MOBILE_ANALYTICS_APP_REGION,
  oauth: {
    domain: process.env.OAUTH_DOMAIN,
    scope: [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin"
    ],
    redirectSignIn:
      typeof window !== "undefined"
        ? `${window.location.origin}/login-success`
        : "http://localhost:3000/login-success",
    redirectSignOut:
      typeof window !== "undefined"
        ? `${window.location.origin}`
        : "http://localhost:3000",
    responseType: "code"
  },
  aws_cognito_social_providers: ["FACEBOOK", "GOOGLE"]
};

Amplify.configure({
  ...awsExports,
  ssr: true
});

if (typeof window !== "undefined") {
  posthog.init(process.env.POSTHOG_API_KEY as string, {
    // Using Next.js rewrites as a reverse proxy to avoid adblockers
    api_host: `${window.location.origin}/ingest`,
    ui_host: "https://app.posthog.com"
    // Enable debug mode in development
    // loaded: posthog => {
    //   if (process.env.NODE_ENV === "development") posthog.debug();
    // }
  });
}

Hub.listen("auth", ({ payload: { data } }) => {
  /** Don't want this to trigger for all failed attempts otherwise it will rerender the login page. */
  if (data?.message === "PreSignUp+failed+with+error+Email+already+in+use.+") {
    useAuthError.setState({ error: "This email is already in use." });
    window.location.href = "/login";
  }
});

export default function App({ Component, pageProps }: MyAppProps) {
  const router = useRouter();
  const Layout =
    Layouts[Component.Layout || "Main"] ?? ((page: unknown) => page);

  useEffect(() => {
    // Track page views
    const handleRouteChange = () => posthog.capture("$pageview");
    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  // This ensures that data is not shared between different users and requests
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 1000 * 60 * 60 * 24, // 24 hours
            cacheTime: 1000 * 60 * 60 * 24, // 24 hours
            refetchOnReconnect: "always"
          }
        }
      })
  );

  return (
    <PostHogProvider client={posthog}>
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <Theme accentColor="teal" radius="small">
            <ThemeProvider theme={theme}>
              <AppHead />
              <Layout>
                <Component {...pageProps} />
                <Script
                  async
                  src="https://unpkg.com/pwacompat"
                  crossOrigin="anonymous"
                ></Script>
              </Layout>
              <PortalGateway />
            </ThemeProvider>
          </Theme>
        </Hydrate>
        <GlobalStyle />
        <OneSignalInitialiser />
      </QueryClientProvider>
    </PostHogProvider>
  );
}
