import { GraphQLSubscription } from "@aws-amplify/api";
import { useQueries, useQueryClient } from "@tanstack/react-query";
import { API } from "aws-amplify";
import {
  OnCreateSessionSubscription,
  OnDeleteSessionSubscription,
  OnUpdateSessionSubscription
} from "features/sessions/types";
import { useSite } from "features/site/hooks/useSite";
import { useUnmount } from "hooks/useUnmount";
import posthog from "posthog-js";
import { getAuthMode } from "utils/getAuthMode";
import { useOfflineUnsubscribe } from "./useOfflineUnsubscribe";

export const subscribeToSessionCreation = async (
  siteID: string,
  invalidateSessions: (activityID: string) => void
) => {
  const authMode = await getAuthMode();
  const subscription = API.graphql<
    GraphQLSubscription<OnCreateSessionSubscription>
  >({
    query: onCreateSession,
    variables: { siteID },
    authMode
  }).subscribe({
    next: ({ value: { data } }) =>
      invalidateSessions(data?.onCreateVisitorSessionBySite?.activityID || ""),
    error: error => {
      posthog.capture("Session Creation Subscription error", error);
      console.error(error);
    }
  });

  return subscription;
};

export const subscribeToSessionUpdates = async (
  siteID: string,
  invalidateSessions: (activityID: string) => void
) => {
  const authMode = await getAuthMode();
  const subscription = API.graphql<
    GraphQLSubscription<OnUpdateSessionSubscription>
  >({
    query: onUpdateSession,
    variables: { siteID },
    authMode
  }).subscribe({
    next: ({ value: { data } }) => {
      invalidateSessions(data?.onUpdateVisitorSessionBySite?.activityID || "");
    },
    error: error => {
      posthog.capture("Session Update Subscription error", error);
      console.error(error);
    }
  });

  return subscription;
};

export const subscribeToSessionDeletion = async (
  siteID: string,
  invalidateSessions: (activityID: string) => void
) => {
  const authMode = await getAuthMode();
  const subscription = API.graphql<
    GraphQLSubscription<OnDeleteSessionSubscription>
  >({
    query: onDeleteSession,
    variables: { siteID },
    authMode
  }).subscribe({
    next: ({ value: { data } }) =>
      invalidateSessions(data?.onDeleteVisitorSessionBySite?.activityID || ""),
    error: error => {
      posthog.capture("Session Delete Subscription error", error);
      console.error(error);
    }
  });

  return subscription;
};

/** Subscribe to Create/Update Sessions */
export const useSessionSubscriptions = () => {
  const queryClient = useQueryClient();
  const { data: { id: siteId } = {} } = useSite();

  const invalidateSessions = (activityId: string) => {
    queryClient.invalidateQueries({ queryKey: ["plans"] });
    queryClient.invalidateQueries({ queryKey: ["timeline"] });
    queryClient.invalidateQueries({ queryKey: ["sessions", activityId] });
  };

  const [createSub, updateSub, deleteSub] = useQueries({
    queries: [
      {
        queryKey: ["onCreateSession", siteId],
        queryFn: () =>
          subscribeToSessionCreation(siteId || "", invalidateSessions),
        enabled: !!siteId,
        retry: true
      },
      {
        queryKey: ["onUpdateSession", siteId],
        queryFn: () =>
          subscribeToSessionUpdates(siteId || "", invalidateSessions),
        enabled: !!siteId,
        retry: true
      },
      {
        queryKey: ["onDeleteSession", siteId],
        queryFn: () =>
          subscribeToSessionDeletion(siteId || "", invalidateSessions),
        enabled: !!siteId,
        retry: true
      }
    ]
  });

  useOfflineUnsubscribe([createSub.data, updateSub.data]);

  /**
   * When hook is unmounted unsubscribe from subs and invalidate
   * queries so if the hook mounts again it will resubscribe
   */
  useUnmount(() => {
    createSub?.data?.unsubscribe();
    updateSub?.data?.unsubscribe();
    deleteSub?.data?.unsubscribe();
    queryClient.invalidateQueries({
      queryKey: ["onCreateSession", siteId]
    });
    queryClient.invalidateQueries({
      queryKey: ["onUpdateSession", siteId]
    });
    queryClient.invalidateQueries({
      queryKey: ["onDeleteSession", siteId]
    });
  });
};

const onCreateSession = /* GraphQL */ `
  subscription OnCreateSession($siteID: ID) {
    onCreateVisitorSessionBySite(siteID: $siteID) {
      id
      activityID
      siteID
    }
  }
`;

const onUpdateSession = /* GraphQL */ `
  subscription OnUpdateSession($siteID: ID) {
    onUpdateVisitorSessionBySite(siteID: $siteID) {
      id
      activityID
      siteID
    }
  }
`;

const onDeleteSession = /* GraphQL */ `
  subscription OnDeleteSession($siteID: ID) {
    onDeleteVisitorSessionBySite(siteID: $siteID) {
      id
      activityID
      siteID
    }
  }
`;
