import { LayoutKeys } from "components/Layouts/index";
import { NextComponentType, NextPage, NextPageContext } from "next";
import { AppProps } from "next/app";
import { Dispatch, SetStateAction } from "react";

export type S3Object = {
  bucket: string;
  key: string;
  region: string;
};

export enum State {
  Archived = "ARCHIVED",
  Draft = "DRAFT",
  Published = "PUBLISHED"
}

export type Location = {
  lat: number;
  lon: number;
};

export type OpeningHours = {
  friday?: TimeRange;
  monday?: TimeRange;
  saturday?: TimeRange;
  sunday?: TimeRange;
  thursday?: TimeRange;
  tuesday?: TimeRange;
  wednesday?: TimeRange;
};

export type TimeRange = {
  closed?: boolean;
  end: string;
  start: string;
};

export type MapSettings = {
  bearing?: number;
  bounds?: [Coord, Coord];
  zoomLevel?: number;
};

export type Coord = [number, number];

export enum SortDirection {
  Asc = "ASC",
  Desc = "DESC"
}

export enum OpeningHoursVisibility {
  Hidden = "HIDDEN",
  Visible = "VISIBLE",
  VisibleWhenClosed = "VISIBLEWHENCLOSED"
}

/** For image uploader. */
export enum Relation {
  MenuItem = "menuItem",
  CoverImage = "coverImage",
  LogoImage = "logoImage",
  OnboardingActivity = "onboardingActivity",
  Notice = "notice",
  SessionTemplate = "sessionTemplate",
  CustomMapIcon = "customMapIcon"
}

export enum Position {
  Top = "top",
  Right = "right",
  Bottom = "bottom",
  Left = "left"
}

export enum Status {
  Closed = "CLOSED",
  Open = "OPEN",
  Unavailable = "UNAVAILABLE"
}

export enum CurrencyIso {
  Aed = "AED",
  Afn = "AFN",
  All = "ALL",
  Amd = "AMD",
  Ang = "ANG",
  Aoa = "AOA",
  Ars = "ARS",
  Aud = "AUD",
  Awg = "AWG",
  Azn = "AZN",
  Bam = "BAM",
  Bbd = "BBD",
  Bdt = "BDT",
  Bgn = "BGN",
  Bhd = "BHD",
  Bif = "BIF",
  Bmd = "BMD",
  Bnd = "BND",
  Bob = "BOB",
  Bov = "BOV",
  Brl = "BRL",
  Bsd = "BSD",
  Btn = "BTN",
  Bwp = "BWP",
  Byr = "BYR",
  Bzd = "BZD",
  Cad = "CAD",
  Cdf = "CDF",
  Che = "CHE",
  Chf = "CHF",
  Chw = "CHW",
  Clf = "CLF",
  Clp = "CLP",
  Cny = "CNY",
  Cop = "COP",
  Cou = "COU",
  Crc = "CRC",
  Cuc = "CUC",
  Cup = "CUP",
  Cve = "CVE",
  Czk = "CZK",
  Djf = "DJF",
  Dkk = "DKK",
  Dop = "DOP",
  Dzd = "DZD",
  Egp = "EGP",
  Ern = "ERN",
  Etb = "ETB",
  Eur = "EUR",
  Fjd = "FJD",
  Fkp = "FKP",
  Gbp = "GBP",
  Gel = "GEL",
  Ghs = "GHS",
  Gip = "GIP",
  Gmd = "GMD",
  Gnf = "GNF",
  Gtq = "GTQ",
  Gyd = "GYD",
  Hkd = "HKD",
  Hnl = "HNL",
  Hrk = "HRK",
  Htg = "HTG",
  Huf = "HUF",
  Idr = "IDR",
  Ils = "ILS",
  Inr = "INR",
  Iqd = "IQD",
  Irr = "IRR",
  Isk = "ISK",
  Jmd = "JMD",
  Jod = "JOD",
  Jpy = "JPY",
  Kes = "KES",
  Kgs = "KGS",
  Khr = "KHR",
  Kmf = "KMF",
  Kpw = "KPW",
  Krw = "KRW",
  Kwd = "KWD",
  Kyd = "KYD",
  Kzt = "KZT",
  Lak = "LAK",
  Lbp = "LBP",
  Lkr = "LKR",
  Lrd = "LRD",
  Lsl = "LSL",
  Lyd = "LYD",
  Mad = "MAD",
  Mdl = "MDL",
  Mga = "MGA",
  Mkd = "MKD",
  Mmk = "MMK",
  Mnt = "MNT",
  Mop = "MOP",
  Mro = "MRO",
  Mur = "MUR",
  Mvr = "MVR",
  Mwk = "MWK",
  Mxn = "MXN",
  Mxv = "MXV",
  Myr = "MYR",
  Mzn = "MZN",
  Nad = "NAD",
  Ngn = "NGN",
  Nio = "NIO",
  Nok = "NOK",
  Npr = "NPR",
  Nzd = "NZD",
  Omr = "OMR",
  Pab = "PAB",
  Pen = "PEN",
  Pgk = "PGK",
  Php = "PHP",
  Pkr = "PKR",
  Pln = "PLN",
  Pyg = "PYG",
  Qar = "QAR",
  Ron = "RON",
  Rsd = "RSD",
  Rub = "RUB",
  Rwf = "RWF",
  Sar = "SAR",
  Sbd = "SBD",
  Scr = "SCR",
  Sdg = "SDG",
  Sek = "SEK",
  Sgd = "SGD",
  Shp = "SHP",
  Sll = "SLL",
  Sos = "SOS",
  Srd = "SRD",
  Ssp = "SSP",
  Std = "STD",
  Svc = "SVC",
  Syp = "SYP",
  Szl = "SZL",
  Thb = "THB",
  Tjs = "TJS",
  Tmt = "TMT",
  Tnd = "TND",
  Top = "TOP",
  Try = "TRY",
  Ttd = "TTD",
  Twd = "TWD",
  Tzs = "TZS",
  Uah = "UAH",
  Ugx = "UGX",
  Usd = "USD",
  Usn = "USN",
  Uyi = "UYI",
  Uyu = "UYU",
  Uzs = "UZS",
  Vef = "VEF",
  Vnd = "VND",
  Vuv = "VUV",
  Wst = "WST",
  Xaf = "XAF",
  Xag = "XAG",
  Xau = "XAU",
  Xba = "XBA",
  Xbb = "XBB",
  Xbc = "XBC",
  Xbd = "XBD",
  Xcd = "XCD",
  Xdr = "XDR",
  Xof = "XOF",
  Xpd = "XPD",
  Xpf = "XPF",
  Xpt = "XPT",
  Xsu = "XSU",
  Xts = "XTS",
  Xua = "XUA",
  Xxx = "XXX",
  Yer = "YER",
  Zar = "ZAR",
  Zmw = "ZMW",
  Zwl = "ZWL"
}

export type ModelOrderConnection = {
  // items: Order[];
  nextToken?: string;
  startedAt?: string;
};

// Custom useState setter function type
export type SetValue<T> = Dispatch<SetStateAction<T>>;

export enum CRUDType {
  Add = "add",
  Remove = "remove"
}

/** Custom Next Props for App and Page */
export type MyPage<P = unknown, IP = P> = NextPage<P, IP> & {
  Layout?: LayoutKeys;
};

export type MyAppProps = AppProps & {
  Component: NextComponentType<NextPageContext, unknown, unknown> & {
    Layout: LayoutKeys;
  };
};
