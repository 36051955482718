import { Box, Show } from "@cloudline/cloudline-styled-components";
import { useVerifyUser } from "features/auth/hooks/useVerifyUser";
import { useSite } from "features/site/hooks/useSite";
import { useMobile } from "hooks/useMobile";
import { Image } from "../Image";
import { Navbar } from "../Navbar";
import { UseOnMobile } from "../UseOnMobile";

type Props = {
  children: React.ReactNode;
};

export const AuthLayout = ({ children }: Props) => {
  const { data: { authRequired, logoImage, name } = {} } = useSite();

  useVerifyUser();

  return (
    <Show when={useMobile()} fallback={<UseOnMobile />}>
      <Box h="100%" minH="100%">
        <Box p={8} flex column gap={3}>
          <Box w="100%" h={80}>
            <Image
              s3Key={logoImage?.key || ""}
              alt={name || ""}
              fill
              sizes="100%"
              style={{ objectFit: "contain" }}
            />
          </Box>

          {children}
        </Box>

        <Show when={!authRequired}>
          <Navbar />
        </Show>
      </Box>
    </Show>
  );
};
